import THEME from '../theme';
import { aspectRatioLogoAbout, borderRadiusButtons, linkAppleStore, linkPlayStore, footerSocialData } from '../../config/data';

const breakpoint = 1080;
import { Dimensions } from 'react-native';
const windowHeight = Dimensions.get('window').height;
const colors = [THEME.COLORS.PRIMARY_800, THEME.COLORS.PRIMARY_700]; // Define an array of background colors and text colors

//******************************************************************
// To add gradient background to section, add above containerStyles:: 
// gradientColors = {
//     colors: [
//         'rgba(68, 155, 206, 1)',
//         'rgba(19, 63, 112, 1)',
//         'rgba(19, 23, 54, 1)',
//     ],
//     start: { x: 0, y: 0 },
//     end: { x: 1, y: 0 },
// };
// gradientBackgroundStyles = {
//     position: 'absolute',
//     left: 0,
//     right: 0,
//     top: 0,
//     bottom: 0,
// };


// To add image background to section, add above containerStyles: 
// backgroundImage: {
//     mobile: {
//         uri: require("../../../assets/FotoAboutMobile.jpg")
//     },
//     web: {
//         uri: require("../../../assets/FotoAbout.jpg")
//     },
// },


// To add solid color background to section, add in containerStyles: 
// containerStyles = (width) => ({
//     flexBasis: "auto",
//     backgroundColor: "green" // (Optional) The solid color to use as the background
// });

//******************************************************************

const instagramLink = footerSocialData.find(obj => obj.name === "instagram").link;
const instagramName = footerSocialData.find(obj => obj.name === "instagram").profile;
const whatsappLink = footerSocialData.find(obj => obj.name === "whatsapp").link;
const whatsappNumber = footerSocialData.find(obj => obj.name === "whatsapp").number;


export const clientConfig = [
    {
        name: "lotus-escola-yoga",
        pages: [
            {
                name: "about",
                sections: [
                    {
                        header: {
                            //add a logo property if there is a logo at header
                            logo: {
                                style: (width) => ({
                                    aspectRatio: aspectRatioLogoAbout,
                                    resizeMode: "contain",
                                    paddingVertical: width < breakpoint ? "1rem" : "1.5rem",
                                    marginHorizontal: width < breakpoint ? "1rem" : "1.5rem",
                                    marginTop: "1rem",
                                }),
                                uri: require('../../../assets/LogoAbout.png'),
                            },
                            buttonLogin:
                            {
                                style: (width) => ({
                                    padding: "0.3rem",
                                    marginTop: "1rem"
                                }),
                                textStyle: (width) => ({
                                    color: THEME.COLORS.TEXT_BUTTON,
                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                    fontSize: width < breakpoint ? 14 : 16,
                                    paddingHorizontal: width < breakpoint ? "0rem" : "1rem",
                                    paddingLeft: width < breakpoint ? "0.5rem" : "1rem"
                                }),
                            },
                            buttonApp: {
                                style: (width) => ({
                                    backgroundColor: THEME.COLORS.PRIMARY_900,
                                    padding: "0.5rem",
                                    borderRadius: borderRadiusButtons,
                                    paddingVertical: 8,
                                    paddingHorizontal: "1rem",
                                    marginTop: "1rem",
                                }),
                                textStyle: (width) => ({
                                    color: THEME.COLORS.TEXT_BUTTON,
                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                    fontSize: width < breakpoint ? 14 : 16,
                                }),
                            }
                        },
                        columns: [
                            // {
                            //     style: (width, columnWidth) => ({
                            //         flexDirection: 'row',
                            //         justifyContent: 'space-between',
                            //         alignItems: 'center',
                            //         padding: width < breakpoint ? "1rem" : "2rem",
                            //         width: width < breakpoint ? '100%' : "50%",
                            //         alignItems: "center",
                            //         order: width < breakpoint ? 2 : 2,
                            //     }),
                            //     items: [
                            //         {
                            //             type: 'image',
                            //             uri: require('../../../assets/Computador.png'),
                            //             aspectRatio: 1.25, //padrão da imagem
                            //             styles: (width) => ({
                            //                 container: {
                            //                     width: width < breakpoint ? "100%" : "100%",
                            //                     marginBottom: width < breakpoint ? "0.5rem" : "3rem",
                            //                     marginTop: width < breakpoint ? "0.5rem" : "3rem",
                            //                 },
                            //                 image: {
                            //                     width: '100%',
                            //                     resizeMode: "contain",
                            //                 },
                            //             }),
                            //         },
                            //     ]
                            // },
                            {
                                style: (width) => ({
                                    justifyContent: 'center',
                                    flex: 1,
                                    alignItems: width < breakpoint ? 'center' : 'center',
                                    paddingTop: width < breakpoint ? "1rem" : "0rem",
                                    paddingBottom: width < breakpoint ? "2rem" : "2rem",
                                    order: width < breakpoint ? 1 : 1,
                                    width: width < breakpoint ? "100%" : "100%",
                                }),
                                items: [
                                    // {
                                    //     type: 'image',
                                    //     uri: require('../../../assets/BRANCO.png'),
                                    //     aspectRatio: aspectRatioLogoAbout,
                                    //     styles: (width) => ({
                                    //         container: {
                                    //             width: width < breakpoint ? "80%" : "30%",
                                    //             marginBottom: width < breakpoint ? "14rem" : "13rem",
                                    //             marginTop: width < breakpoint ? "0rem" : "0rem",
                                    //             alignItems: width < breakpoint ? "center" : "center",
                                    //         },
                                    //         image: {
                                    //             width: '100%',
                                    //             resizeMode: "contain",
                                    //         },
                                    //     }),
                                    // },
                                    // {
                                    //     type: 'text',
                                    //     text: 'Sua escola de Yôga em qualquer lugar',
                                    //     style: (width) => ({
                                    //         fontSize: width < breakpoint ? 20 : 32,
                                    //         width: width < breakpoint ? "100%" : "100%",
                                    //         color: "#FFFFFF", //colocar uma cor que combine com a foto de fundo
                                    //         textAlign: width < breakpoint ? "center" : "center",
                                    //         marginBottom: width < breakpoint ? "1rem" : "1rem",
                                    //         fontFamily: THEME.FONTFAMILY.SUBTITLE,
                                    //     })
                                    // },
                                    {
                                        type: 'plansButton',
                                        text: 'Quero começar agora',
                                        buttonStyle: (width) => ({
                                            backgroundColor: THEME.COLORS.PRIMARY_900,
                                            paddingVertical: width < breakpoint ? 14 : 18,
                                            marginTop: windowHeight * 0.85,
                                            paddingHorizontal: width < breakpoint ? 20 : 28,
                                            borderRadius: borderRadiusButtons,
                                            alignSelf: width < breakpoint ? "center" : "center",
                                        }),
                                        textStyle: (width) => ({
                                            fontSize: width < breakpoint ? 16 : 18,
                                            fontWeight: "bold",
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            textAlign: width < breakpoint ? "flex-end" : "center",
                                        }),
                                    },
                                ]
                            }
                        ],
                        backgroundImage: {
                            mobile: {
                                uri: require("../../../assets/FotoAboutMobile.jpg")
                            },
                            web: {
                                uri: require("../../../assets/FotoAbout.jpg")
                            },
                        },
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            //backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                        }),
                        contentStyles: (width) => ({
                            alignItems: width < breakpoint ? "center" : "center",
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexDirection: width < breakpoint ? "column" : "row",
                            flexBasis: "auto",
                            paddingHorizontal: width < breakpoint ? "2rem" : "5rem",
                            width: window.innerWidth,
                            height: windowHeight
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    marginTop: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'video',
                                        uri: 'https://youtu.be/SY01CyfyoDo',
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                            paddingHorizontal: width < breakpoint ? "1rem" : "10rem",
                            paddingBottom: 60,
                            paddingTop: 10,
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'O que torna a Escola Lótus especial?',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 35 : 60,
                                            color: THEME.COLORS.PRIMARY_800,
                                            textAlign: "center",
                                            marginBottom: 35,
                                            marginTop: 50,
                                            fontWeight: 'bold',
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            padding: "10px",
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Descubra uma nova forma de vivenciar o Yôga no seu próprio ritmo e de acordo com a sua rotina.',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 18 : 30,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            marginBottom: 25,
                                            fontFamily: THEME.FONTFAMILY.SUBTITLE,
                                            lineHeight: width < breakpoint ? 30 : 35,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "1rem",
                                            fontWeight: 'bold',
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Experimente aulas ao vivo e interativas, flexibilidade total para adequar as práticas à sua rotina, uma abordagem acessível que nutre corpo e mente, uma comunidade acolhedora e um atendimento personalizado de excelência. Você encontrará tudo isso e muito mais na Escola Lótus.',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 16 : 26,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            marginBottom: 40,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            lineHeight: width < breakpoint ? 30 : 35,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "1rem",
                                        })
                                    },
                                    {
                                        type: 'image',
                                        uri: require('../../../assets/FotoAboutMe.jpg'),
                                        aspectRatio: 1, //AJUSTAR CONFORME IMAGEM
                                        styles: (width) => ({
                                            container: {
                                                width: width < breakpoint ? "70%" : "25%",
                                                marginBottom: "2rem",
                                                marginTop: width < breakpoint ? "2rem" : "2rem",
                                            },
                                            image: {
                                                width: '100%',
                                                resizeMode: "cover",
                                                borderRadius: '50%',
                                            },
                                        }),
                                    },
                                    {
                                        type: 'text',
                                        text: 'Vivi Holder',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 18 : 28,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            marginBottom: 20,
                                            fontFamily: THEME.FONTFAMILY.SUBTITLE,
                                            lineHeight: width < breakpoint ? 30 : 35,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "1rem",
                                            fontWeight: 'bold',
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'A Vivi é uma das idealizadoras da Escola Lótus, responsável por grande parte das aulas da escola. Formada em SwáSthya Yôga por duas instituições e em Hatha Yôga com certificação validada internacionalmente. Atualmente ministra o curso de formação da Escola Lótus, capacitando seus alunos a darem aulas com maestria. Possui também cursos em diversas plataformas online, conduzindo assim milhares de alunos. É uma referência em Yôga no Brasil.',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 13 : 22,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            marginBottom: 40,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            lineHeight: width < breakpoint ? 30 : 35,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "1rem",
                                        })
                                    },
                                    {
                                        type: 'image',
                                        uri: require('../../../assets/Fotomari.jpeg'),
                                        aspectRatio: 1, //AJUSTAR CONFORME IMAGEM
                                        styles: (width) => ({
                                            container: {
                                                width: width < breakpoint ? "70%" : "25%",
                                                marginBottom: "2rem",
                                                marginTop: width < breakpoint ? "2rem" : "2rem",
                                            },
                                            image: {
                                                width: '100%',
                                                resizeMode: "cover",
                                                borderRadius: '50%',
                                            },
                                        }),
                                    },
                                    {
                                        type: 'text',
                                        text: 'Mari Pratti',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 18 : 28,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            marginBottom: 20,
                                            fontFamily: THEME.FONTFAMILY.SUBTITLE,
                                            lineHeight: width < breakpoint ? 30 : 35,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "1rem",
                                            fontWeight: 'bold',
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'A Mari é formada como professora de Yôga pelo We Love Yôga Studio com cursos voltados para anatomia aplicada ao Yôga, chakras e Yôga sutras. Também ministra aulas presenciais no São Paulo Futebol Clube (SPFC) para os mais diversos públicos e faixas etárias. Atualmente participa da Mentoria exclusiva para instrutores da escola, uma forma de se capacitar continuamente.',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 13 : 22,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            marginBottom: 40,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            lineHeight: width < breakpoint ? 30 : 35,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "1rem",
                                        })
                                    },
                                    {
                                        type: 'image',
                                        uri: require('../../../assets/Fotosandra.jpg'),
                                        aspectRatio: 1, //AJUSTAR CONFORME IMAGEM
                                        styles: (width) => ({
                                            container: {
                                                width: width < breakpoint ? "70%" : "25%",
                                                marginBottom: "2rem",
                                                marginTop: width < breakpoint ? "2rem" : "2rem",
                                            },
                                            image: {
                                                width: '100%',
                                                resizeMode: "cover",
                                                borderRadius: '50%',
                                            },
                                        }),
                                    },
                                    {
                                        type: 'text',
                                        text: 'Sandra Unterleider',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 18 : 28,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            marginBottom: 20,
                                            fontFamily: THEME.FONTFAMILY.SUBTITLE,
                                            lineHeight: width < breakpoint ? 30 : 35,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "1rem",
                                            fontWeight: 'bold',
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'A Sandra é professora de Yôga formada pelo curso de formação da Escola Lótus. Iniciou no Yôga com cursos ministrados por Abhijata Iyengar (Iyengar Yôga) na Indonésia. Mas foi com o Swásthya Yôga que se conectou. Atualmente participa da Mentoria exclusiva para  instrutores da escola, uma forma de se capacitar continuamente.',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 13 : 22,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            marginBottom: 40,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            lineHeight: width < breakpoint ? 30 : 35,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "1rem",
                                        })
                                    },
                                    {
                                        type: 'plansButton',
                                        text: 'Quero Praticar na Escola',
                                        buttonStyle: (width) => ({
                                            backgroundColor: THEME.COLORS.PRIMARY_900,
                                            paddingVertical: width < breakpoint ? '1rem' : '1.5rem',
                                            marginBottom: 10,
                                            paddingHorizontal: width < breakpoint ? '0.5rem' : '4rem',
                                            borderRadius: borderRadiusButtons,
                                            alignSelf: "center",
                                        }),
                                        textStyle: (width) => ({
                                            fontSize: width < breakpoint ? 18 : 25,
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            fontWeight: 'bold',
                                            textAlign: "center",
                                        }),
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                            paddingHorizontal: width < breakpoint ? "1rem" : "10rem",
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingTop: width < breakpoint ? "2rem" : "5rem",
                                    paddingBottom: width < breakpoint ? "2rem" : "5rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                }),
                                items: [
                                    {
                                        type: 'multipleTexts',
                                        content: [
                                            {
                                                text: 'Pratique, conecte-se consigo mesmo e', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 40,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    paddingBottom: width < breakpoint ? "0.1rem" : "0px",
                                                    paddingHorizontal: width < breakpoint ? "0.5rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'EVOLUA', style: (width) => ({
                                                    fontSize: width < breakpoint ? 25 : 45,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.PRIMARY_900,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                        ],
                                    },
                                    {
                                        type: 'imageCards',
                                        arrowBackgroundColor: {
                                            lighter: `${THEME.COLORS.PRIMARY_800}A0`,
                                            darker: THEME.COLORS.PRIMARY_800
                                        },
                                        info: [
                                            {
                                                title: "Jornada Iniciante",
                                                infos: "Um ponto de partida perfeito para sua jornada no Yôga. Tenha acesso a aulas práticas exclusivas e orientação especializada para começar sua prática com confiança.",
                                                image: require("../../../assets/FotoCategoria1.png"),
                                            },
                                            {
                                                title: "Acesso VIP às Aulas ao Vivo",
                                                infos: "Desfrute de um acesso privilegiado a todas as aulas ao vivo, permitindo que você mergulhe profundamente na prática de Yôga com instrutores renomados. Participe, interaja e aprimore sua técnica em tempo real.",
                                                image: require("../../../assets/FotoCategoria2.png"),
                                            },
                                            {
                                                title: "Diversidade de Práticas Completas",
                                                infos: "Tenha acesso a um conjunto abrangente de aulas práticas, abordando desde iniciantes até níveis avançados, força, flexibilidade e técnicas de inversão. Explore diferentes elementos do Yôga e aprimore sua jornada.",
                                                image: require("../../../assets/FotoCategoria3.png"),
                                            },
                                            {
                                                title: "Clube do Livro",
                                                infos: "Faça parte de um grupo exclusivo de estudantes dedicados à evolução pessoal. Participe de encontros periódicos nos quais discutimos obras inspiradoras que aprofundam o conhecimento do Yôga e do desenvolvimento humano.",
                                                image: require("../../../assets/FotoCategoria4.png"),
                                            },
                                            {
                                                title: "Yôga Flex",
                                                infos: "Um programa completo que já conduziu milhares de alunos. Pratique Yôga em casa, com segurança e desenvolva de forma rápida e eficaz flexibilidade, força e autoconhecimento. Você terá acesso ilimitado para praticar quando e onde desejar.",
                                                image: require("../../../assets/FotoCategoria5.png"),
                                            },
                                            {
                                                title: "Workshop Despertando a Libido",
                                                infos: "Explore o poder do Yôga para despertar a energia interior adormecida em um workshop especial. Mergulhe em aulas teóricas e práticas, aprendendo sobre os centros energéticos do corpo e como equilibrá-los para uma vida plena.",
                                                image: require("../../../assets/FotoCategoria6.png"),
                                            },
                                            {
                                                title: "Workshop Domine o Jejum",
                                                infos: "Aprenda sobre os benefícios e técnicas seguras de jejum em um workshop exclusivo, correlacionando o Jejum com o Yôga. Descubra como incorporar o jejum em sua vida para melhorar sua saúde e bem-estar.",
                                                image: require("../../../assets/FotoCategoria7.png"),
                                            },
                                            {
                                                title: "Bônus Exclusivos e Convidados Especiais",
                                                infos: "Desfrute de bônus surpreendentes, incluindo aulas extras com convidados especiais. Tenha acesso a perspectivas únicas e experiências enriquecedoras para impulsionar ainda mais sua jornada no Yôga.",
                                                image: require("../../../assets/FotoCategoria8.png"),
                                            },
                                        ],
                                        cardStyle: (width) => ({
                                            width: "16rem",
                                            height: '100%',
                                            marginHorizontal: "1rem",
                                            backgroundColor: "transparent",
                                        }),
                                        cardCoverStyle: (width) => ({
                                            width: "100%",
                                            height: "9rem",
                                            borderRadius: "12px",
                                        }),
                                        titleStyle: (width) => ({
                                            fontWeight: "bold",
                                            paddingVertical: "0.5rem",
                                            textAlign: "left",
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            fontFamily: THEME.FONTFAMILY.SUBTITLE
                                        }),
                                        infoStyle: (width) => ({
                                            textAlign: "left",
                                            fontSize: THEME.FONTSIZE.EXTRASMALL,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            fontFamily: THEME.FONTFAMILY.TEXTS
                                        }),
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                items: [
                                    {
                                        type: 'iconCards',
                                        color: THEME.COLORS.PRIMARY_900,
                                        titleStyle: (width) => ({
                                            fontSize: 20,
                                            fontWeight: 'bold',
                                            marginBottom: 5,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        descriptionStyle: (width) => ({
                                            fontSize: 18,
                                            textAlign: "center",
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        cardData: [
                                            {
                                                icon: 'shield-checkmark-outline',
                                                title: 'PAGAMENTO SEGURO',
                                                description: 'Tranquilidade nas suas transações.',
                                            },
                                            {
                                                icon: 'calendar',
                                                title: 'ACESSE AGORA MESMO',
                                                description: 'Inicie sua jornada imediatamente.',
                                            },
                                            {
                                                icon: 'medal',
                                                title: 'GARANTIA DE QUALIDADE',
                                                description: 'Experimente 7 dias grátis.\nSua satisfação é nossa prioridade.',
                                            },
                                        ]
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                            padding: "3rem",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingVertical: width < breakpoint ? "2rem" : "5rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'DEPOIMENTOS',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 26 : 38,
                                            color: THEME.COLORS.PRIMARY_900,
                                            textAlign: "center",
                                            marginBottom: 20,
                                            fontFamily: THEME.FONTFAMILY.SUBTITLE,
                                        })
                                    },
                                    {
                                        type: 'testimonials',
                                        arrowBackgroundColor: {
                                            lighter: `${THEME.COLORS.PRIMARY_800}A0`,
                                            darker: THEME.COLORS.PRIMARY_800
                                        },
                                        info: [
                                            {
                                                // title: "~ John",
                                                // infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque. Aut numquam corporis non iste assumend."',
                                                image: require("../../../assets/Testimonial1.jpg"), //add image if the testimonial is an image
                                            },
                                            {
                                                // title: "~ Kamesh",
                                                // infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                                                image: require("../../../assets/Testimonial2.jpg"), //add image if the testimonial is an image
                                            },
                                            {
                                                // title: "~ Nobita",
                                                // infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque. Aut numquam corporis non iste assumend. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                                                image: require("../../../assets/Testimonial3.jpg"), //add image if the testimonial is an image
                                            },
                                            {
                                                // title: "~ Juliet",
                                                // infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                                                image: require("../../../assets/Testimonial4.jpg"), //add image if the testimonial is an image
                                            },
                                            {
                                                // title: "~ Juliet",
                                                // infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                                                image: require("../../../assets/Testimonial5.jpg"), //add image if the testimonial is an image
                                            },
                                            {
                                                // title: "~ Juliet",
                                                // infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                                                image: require("../../../assets/Testimonial6.jpg"), //add image if the testimonial is an image
                                            },
                                            {
                                                // title: "~ Juliet",
                                                // infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                                                image: require("../../../assets/Testimonial7.jpg"), //add image if the testimonial is an image
                                            },
                                            {
                                                // title: "~ Juliet",
                                                // infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                                                image: require("../../../assets/Testimonial8.jpg"), //add image if the testimonial is an image
                                            },
                                        ],
                                        cardStyle: (width) => ({
                                            width: "20rem",
                                            height: '100%',
                                            marginHorizontal: "2rem",
                                            backgroundColor: "transparent",
                                        }),
                                        titleStyle: (width) => ({
                                            paddingVertical: "1rem",
                                            textAlign: "center",
                                            fontSize: THEME.FONTSIZE.STANDARD,
                                            color: THEME.COLORS.PRIMARY_900,
                                            fontFamily: THEME.FONTFAMILY.SUBTITLE
                                        }),
                                        infoStyle: (width) => ({
                                            textAlign: "center",
                                            padding: "0.5rem",
                                            fontSize: THEME.FONTSIZE.SMALL,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            fontFamily: THEME.FONTFAMILY.TEXTS
                                        }),
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Que aluno você quer ser?',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 28 : 50,
                                            fontWeight: 'bold',
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            paddingVertical: width < breakpoint ? '2rem' : '2rem',
                                            paddingHorizontal: width < breakpoint ? '1rem' : '3rem',
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            width: "100%",
                                            textAlign: "center",
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Teste grátis por 7 dias. Pagamento automático no boleto ou no cartão de crédito.',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 14 : 20,
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            paddingBottom: 20,
                                            paddingHorizontal: width < breakpoint ? '1rem' : '3rem',
                                            width: "100%",
                                            textAlign: "center",
                                        })
                                    },
                                    {
                                        type: 'plansCardsWithoutFlatlist',
                                        bestOptionColor: "#00517B",
                                        arrowBackgroundColor: {
                                            lighter: `${THEME.COLORS.PRIMARY_900}A0`,
                                            darker: THEME.COLORS.PRIMARY_900
                                        },
                                        cardStyle: (width, index) => ({
                                            width: width < breakpoint ? width * 0.8 : width * 0.30,
                                            height: "100%",
                                            borderWidth: "4px",
                                            borderStyle: "solid",
                                            borderColor: THEME.COLORS.PRIMARY_700,
                                            backgroundColor: index < 1 ? THEME.COLORS.PRIMARY_700 : THEME.COLORS.PRIMARY_800, // Alternate the background color based on the index
                                        }
                                        ),
                                        titleStyle: (width, index) => ({
                                            fontSize: width < breakpoint ? 34 : 40,
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            color: index < 1 ? THEME.COLORS.PRIMARY_800 : THEME.COLORS.PRIMARY_700, // Alternate the text color based on the index
                                        }),
                                        pricingStyle: (width, index) => ({
                                            fontSize: width < breakpoint ? 38 : 50,
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            color: index < 1 ? THEME.COLORS.PRIMARY_800 : THEME.COLORS.PRIMARY_700, // Alternate the text color based on the index
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                        }),
                                        infoStyle: (width, index) => ({
                                            textAlign: "left",
                                            fontSize: width < breakpoint ? 14 : 16,
                                            color: index < 1 ? THEME.COLORS.PRIMARY_800 : THEME.COLORS.PRIMARY_700, // Alternate the text color based on the index
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        buttonStyle: (width, index) => ({
                                            color: index < 1 ? THEME.COLORS.PRIMARY_800 : THEME.COLORS.PRIMARY_700, // Alternate the text color based on the index
                                            style: {
                                                borderRadius: borderRadiusButtons,
                                                paddingVertical: width < breakpoint ? 14 : 18,
                                            },
                                            titleStyle: {
                                                fontWeight: 'bold',
                                                color: index < 1 ? THEME.COLORS.PRIMARY_700 : THEME.COLORS.PRIMARY_800, // Alternate the text color based on the index
                                                fontFamily: THEME.FONTFAMILY.TITLE,
                                                fontSize: width < breakpoint ? 16 : 22,
                                            },
                                        }),
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.PRIMARY_800,
                            paddingVertical: "2rem"
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingTop: width < breakpoint ? "1rem" : "5rem",
                                    paddingBottom: width < breakpoint ? "1rem" : "5rem",
                                    paddingHorizontal: width < breakpoint ? "0.5rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                }),
                                items: [
                                    {
                                        type: 'multipleTexts',
                                        content: [
                                            {
                                                text: 'Você também pode comprar os', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 40,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    paddingBottom: width < breakpoint ? "0.1rem" : "0px",
                                                    paddingHorizontal: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'CONTEÚDOS SEPARADAMENTE', style: (width) => ({
                                                    fontSize: width < breakpoint ? 25 : 45,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.PRIMARY_900,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'para ter os', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 50,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'RESULTADOS', style: (width) => ({
                                                    fontSize: width < breakpoint ? 30 : 50,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.PRIMARY_900,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                                })
                                            },
                                            {
                                                text: 'QUE VOCÊ', style: (width) => ({
                                                    fontSize: width < breakpoint ? 30 : 50,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                                })
                                            },
                                            {
                                                text: 'DESEJA', style: (width) => ({
                                                    fontSize: width < breakpoint ? 30 : 50,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.PRIMARY_900,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                                })
                                            },
                                        ],
                                    },
                                    {
                                        type: 'coursesCards',
                                        arrowBackgroundColor: {
                                            lighter: `${THEME.COLORS.PRIMARY_800}A0`,
                                            darker: THEME.COLORS.PRIMARY_800
                                        },
                                        cardStyle: (width) => ({
                                            width: "18rem",
                                            height: '100%',
                                            marginHorizontal: "1rem",
                                            backgroundColor: "transparent",
                                        }),
                                        cardCoverStyle: (width) => ({
                                            width: "100%",
                                            height: '10.12rem',
                                            borderRadius: '12px',
                                        }),
                                        titleStyle: (width) => ({
                                            textAlign: "left",
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            marginVertical: "0.5rem",
                                            fontFamily: THEME.FONTFAMILY.SUBTITLE
                                        }),
                                        priceStyle: (width) => ({
                                            textAlign: "left",
                                            fontSize: 20,
                                            fontWeight: 'bold',
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            paddingBottom: "0.5rem",
                                            fontFamily: THEME.FONTFAMILY.TEXTS
                                        }),
                                        infoStyle: (width) => ({
                                            textAlign: "left",
                                            fontSize: 15,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            marginVertical: "0.5rem",
                                            fontFamily: THEME.FONTFAMILY.TEXTS
                                        }),
                                        buttonStyle: (width) => ({
                                            backgroundColor: THEME.COLORS.PRIMARY_800,
                                            borderRadius: borderRadiusButtons,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        textStyle: (width) => ({
                                            fontSize: width < breakpoint ? 18 : 22,
                                            color: THEME.COLORS.PRIMARY_700,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "1rem",
                                    width: width < breakpoint ? '100%' : "50%",
                                    alignItems: "center",
                                    order: width < breakpoint ? 2 : 1,
                                }),
                                items: [
                                    {
                                        type: 'image',
                                        uri: require('../../../assets/FotoAppStore.jpg'),
                                        aspectRatio: 1.4, //padrão da imagem
                                        styles: (width) => ({
                                            container: {
                                                width: width < breakpoint ? "100%" : "100%",
                                                marginBottom: width < breakpoint ? "1rem" : "3rem",
                                                marginTop: width < breakpoint ? "1rem" : "3rem",
                                            },
                                            image: {
                                                width: '100%',
                                                resizeMode: "contain",
                                            },
                                        }),
                                    },
                                ]
                            },
                            {
                                style: (width) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "1rem",
                                    order: width < breakpoint ? 1 : 2,
                                    width: width < breakpoint ? "100%" : "50%",
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'DISPONÍVEL PARA BAIXAR NAS LOJAS DE APLICATIVOS',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 26 : 38,
                                            color: THEME.COLORS.PRIMARY_800,
                                            textAlign: "center",
                                            marginBottom: 30,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            paddingTop: width < breakpoint ? "2rem" : "0rem"
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Tenha todo o conteúdo da nossa escola em seu celular, no aplicativo EXCLUSIVO, para você praticar de onde estiver!\n\nClique para baixar:',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 20 : 24,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: width < breakpoint ? "center" : "center",
                                            marginBottom: 30,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        })
                                    },
                                    {
                                        type: 'appleStoreButton',
                                        uri: linkAppleStore,
                                    },
                                    {
                                        type: 'googlePlayButton',
                                        uri: linkPlayStore,
                                    },
                                ]
                            }
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT
                        }),
                        contentStyles: (width) => ({
                            alignItems: width < breakpoint ? "center" : "center",
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexDirection: width < breakpoint ? "column" : "row",
                            flexBasis: "auto",
                            paddingHorizontal: width < breakpoint ? "2rem" : "5rem",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Precisa de ajuda para ESCOLHER SEU PLANO ou gostaria de agendar uma AULA EXPERIMENTAL?',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 28 : 30,
                                            fontWeight: 'bold',
                                            color: THEME.COLORS.PRIMARY_900,
                                            width: "100%",
                                            textAlign: "center",
                                            marginTop: 50,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            padding: "10px",
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Nos envie uma mensagem:',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 24 : 28,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            width: "100%",
                                            textAlign: "center",
                                            marginBottom: 20,
                                            marginTop: 5,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            padding: "10px",
                                        })
                                    },
                                    {
                                        type: 'iconCards',
                                        color: THEME.COLORS.PRIMARY_900,
                                        titleStyle: (width) => ({
                                            fontSize: 20,
                                            fontWeight: 'bold',
                                            marginBottom: 5,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        descriptionStyle: (width) => ({
                                            fontSize: 16,
                                            textAlign: "center",
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        cardData: [
                                            {
                                                icon: 'logo-whatsapp',
                                                title: 'Fale comigo por WhatsApp',
                                                description: whatsappNumber,
                                                uri: whatsappLink,
                                            },
                                            {
                                                icon: 'logo-instagram',
                                                title: 'Instagram',
                                                description: instagramName,
                                                uri: instagramLink,
                                            },
                                            // add more icons here
                                            // {
                                            //     icon: 'mail',
                                            //     title: 'Atendimento por E-mail',
                                            //     description: 'contato@blixtecnologia.com.br',
                                            // },
                                        ],
                                    },
                                ]
                            },
                        ],
                        gradientColors: {
                            colors: [
                                THEME.COLORS.PRIMARY_700,
                                'rgba(255, 255, 255, 1)',
                                'rgba(255, 255, 255, 1)',
                            ],
                            start: { x: 0, y: 0 },
                            end: { x: 1, y: 1 },
                        },
                        gradientBackgroundStyles: {
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        },
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Perguntas frequentes',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 25 : 34,
                                            color: THEME.COLORS.PRIMARY_900,
                                            textAlign: "center",
                                            textDecorationLine: "underline",
                                            textDecorationColor: THEME.COLORS.PRIMARY_900,
                                            marginBottom: 8,
                                            marginTop: 50,
                                            fontWeight: 'bold',
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            padding: "10px",
                                        })
                                    },
                                    {
                                        type: 'FAQ',
                                        questionStyle: (width, expandedId, question) => ({
                                            flex: 1,
                                            fontSize: width < breakpoint ? 16 : 18,
                                            fontWeight: 'bold',
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            color: expandedId === question.id ? THEME.COLORS.PRIMARY_700 : THEME.COLORS.TEXT_ABOUT
                                        }),
                                        answerStyle: (width) => ({
                                            fontSize: width < breakpoint ? 16 : 18,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            color: THEME.COLORS.TEXT_ABOUT
                                        }),
                                        containerStyle: (width) => ({
                                            backgroundColor: 'transparent',
                                            elevation: 5,
                                            margin: 10,
                                            overflow: 'hidden',
                                            borderBottomWidth: 1.5,
                                            borderBottomColor: THEME.COLORS.TEXT_ABOUT,
                                        }),
                                        questions: linkPlayStore !== null && linkAppleStore !== null ? [
                                            {
                                                id: 1,
                                                question: 'Quem nunca praticou Yôga pode entrar para a escola?',
                                                answer: 'Sim. As aulas são separadas por níveis, iniciante, intermediário e avançado para que todos consigam ter aulas para assistir. Além dos guias e materiais gravados para começar com o coração tranquilo.',
                                            },
                                            {
                                                id: 2,
                                                question: 'As aulas ficam gravadas?',
                                                answer: 'Sim, as aulas ao vivo das professoras da escola são gravadas, e é disponibilizado o replay durante 5 dias após a data da aula. Algumas aulas especiais ficam gravadas e sempre disponíveis para você praticar quando quiser, como o curso Yôga Flex, aulas teóricas e eventos especiais. (A disponibilidade das gravações varia de acordo com o plano escolhido).',
                                            },
                                            {
                                                id: 3,
                                                question: 'Tenho que reservar o horário para praticar?',
                                                answer: 'Não. Você não precisa fazer reservas, basta acessar a aula no dia e horário de acordo com o calendário vigente. Simples assim, é só clicar no link da aula.',
                                            },
                                            {
                                                id: 4,
                                                question: 'Como faço para esclarecer minhas dúvidas?',
                                                answer: 'Você pode esclarecer suas dúvidas no momento das aulas, diretamente com o professor. Basta escrever no chat ou ligar o seu microfone. Além disso, você pode entrar em contato conosco em nossos canais de comunicação.',
                                            },
                                            {
                                                id: 5,
                                                question: 'Como faço para comprar um produto no aplicativo?',
                                                answer: 'Você compra e gerencia suas compras pela versão web do aplicativo, através desse link que você está.',
                                            },
                                            {
                                                id: 6,
                                                question: 'Como faço para acessar os conteúdos?',
                                                answer: 'Você pode acessar o conteúdo por aqui, basta clicar no botão “Visualizar Conteúdo” ou baixar o aplicativo nas lojas.',
                                            },
                                            {
                                                id: 7,
                                                question: 'Como faço para acessar as aulas ao vivo?',
                                                answer: 'Você pode clicar no link da aula disponibilizado dentro do aplicativo na categoria "Acesso às aulas ao vivo" no dia e horário que você quer praticar, ou acessar nosso grupo exclusivo do WhatsApp onde enviamos os links alguns minutos antes das aulas e também avisos importantes.',
                                            },
                                            {
                                                id: 8,
                                                question: 'Quais as formas de pagamento?',
                                                answer: 'As opções de pagamento são cartão de crédito de forma recorrente (não compromete o limite do cartão com o valor total, apenas o valor da parcela mês a mês) ou boleto.',
                                            },
                                            {
                                                id: 9,
                                                question: 'É preciso se cadastrar para ter acesso aos conteúdos gratuitos?',
                                                answer: 'Não é necessário, os conteúdos gratuitos estão abertos para todos visualizarem. Basta você acessar a opção “Visualizar Conteúdo” e acessar os conteúdos desbloqueados.',
                                            },
                                            {
                                                id: 10,
                                                question: 'Após eu finalizar o pagamento, irei receber algum email de confirmação?',
                                                answer: 'Sim! Você receberá um e-mail de boas-vindas com suas credenciais de login e um e-mail de confirmação do pagamento.',
                                            },
                                            {
                                                id: 11,
                                                question: 'Como faço para me cadastrar no aplicativo?',
                                                answer: 'O cadastro é feito somente para as pessoas que querem ter acesso aos conteúdos pagos. Após você escolher o produto que deseja comprar, você fará um cadastro e será redirecionado para a página de pagamento.',
                                            },
                                            {
                                                id: 12,
                                                question: 'Consigo cancelar a qualquer momento?',
                                                answer: 'Somente planos mensais podem ser cancelados a qualquer momento pelo aplicativo. Caso você tenha um plano de maior recorrência (trimestral ou anual), o cancelamento deve ser solicitado formalmente através do e-mail atendimento@lotusescoladeyoga.com.br.',
                                            },
                                            {
                                                id: 13,
                                                question: 'Tive problema com o pagamento, posso alterar a forma de pagamento ou os dados do cartão?',
                                                answer: 'Claro. Se algum problema acontecer com o pagamento, você será informado por e-mail e poderá alterar os dados por aqui, acessando "Meu perfil" no ícone superior esquerdo e clicando no botão "Editar meu plano". Você também pode entrar em contato pelos nossos canais de comunicação.',
                                            },
                                        ] : [
                                            {
                                                id: 2,
                                                question: 'Como faço para comprar um produto no aplicativo?',
                                                answer: 'Você compra e gerencia suas compras pela versão web do aplicativo, através desse link que você está.',
                                            },
                                            {
                                                id: 3,
                                                question: 'Como faço para acessar os conteúdos?',
                                                answer: 'Você pode acessar o conteúdo por aqui, basta clicar no botão “Visualizar Conteúdo”.',
                                            },
                                            {
                                                id: 4,
                                                question: 'Quais são as formas de pagamento aceitas?',
                                                answer: 'As opções de pagamento são cartão de crédito ou boleto.',
                                            },
                                            {
                                                id: 5,
                                                question: 'É preciso ter cadastro para ter acesso aos conteúdos gratuitos do aplicativo?',
                                                answer: 'Não é necessário, os conteúdos gratuitos estão abertos para todos visualizarem! Basta você acessar a opção “Visualizar Conteúdo” e acessar os conteúdos desbloqueados.',
                                            },
                                            {
                                                id: 6,
                                                question: 'Após eu finalizar o pagamento, irei receber algum e-mail de confirmação?',
                                                answer: 'Sim! Você receberá um e-mail de boas-vindas com suas credenciais de login e um e-mail de confirmação do pagamento.',
                                            },
                                            {
                                                id: 7,
                                                question: 'Como faço para me cadastrar no aplicativo? ',
                                                answer: 'O cadastro é feito somente para as pessoas que querem ter acesso aos conteúdos pagos. Após você escolher o produto que deseja comprar, você fará um cadastro e será redirecionado para a página de pagamento.',
                                            },
                                            {
                                                id: 8,
                                                question: 'Consigo cancelar a qualquer momento?',
                                                answer: 'Somente planos mensais podem ser cancelados a qualquer momento pelo aplicativo. Caso você tenha um plano de maior recorrência (trimestral, semestral ou anual), o cancelamento deve ser solicitado formalmente.',
                                            },
                                            {
                                                id: 9,
                                                question: 'Tive um problema com o pagamento, posso alterar a forma de pagamento ou os dados do cartão?',
                                                answer: 'Claro. Se algum problema acontecer com o pagamento, você será informado por e-mail e poderá alterar os dados por aqui, acessando "Meu perfil" no ícone superior esquerdo e clicando no botão "Editar meu plano".',
                                            },
                                        ]
                                    }
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                            paddingHorizontal: width < breakpoint ? "2rem" : "10rem",
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Comece a transformação agora!',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 18 : 30,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            marginTop: 40,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "2rem",
                                        })
                                    },
                                    {
                                        type: 'plansButton',
                                        text: 'QUERO INICIAR',
                                        buttonStyle: (width) => ({
                                            backgroundColor: THEME.COLORS.PRIMARY_900,
                                            paddingVertical: width < breakpoint ? '1rem' : '1rem',
                                            paddingHorizontal: width < breakpoint ? '2rem' : '4rem',
                                            borderRadius: borderRadiusButtons,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            alignSelf: width < breakpoint ? "center" : "center",
                                            marginBottom: 40,
                                            marginTop: 40,
                                        }),
                                        textStyle: (width) => ({
                                            fontSize: 18,
                                            fontWeight: 'bold',
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            textAlign: width < breakpoint ? "flex-end" : "center",
                                        }),
                                    }
                                ]
                            },
                        ],
                        gradientColors: {
                            colors: [
                                THEME.COLORS.PRIMARY_700,
                                'rgba(255, 255, 255, 1)',
                                'rgba(255, 255, 255, 1)',
                            ],
                            start: { x: 0, y: 0 },
                            end: { x: 1, y: 1 },
                        },
                        gradientBackgroundStyles: {
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        },
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },

                    // Add more sections for this client here
                ],
            },
            {
                name: "plans",
                sections: [
                    {
                        columns: [
                            {
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Que aluno você quer ser?',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 28 : 50,
                                            fontWeight: 'bold',
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            paddingVertical: width < breakpoint ? '2rem' : '2rem',
                                            paddingHorizontal: width < breakpoint ? '1rem' : '3rem',
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            width: "100%",
                                            textAlign: "center",
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Teste grátis por 7 dias. Pagamento automático no boleto ou no cartão de crédito.',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 14 : 20,
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            paddingBottom: 20,
                                            paddingHorizontal: width < breakpoint ? '1rem' : '3rem',
                                            width: "100%",
                                            textAlign: "center",
                                        })
                                    },
                                    {
                                        type: 'plansCardsWithoutFlatlist',
                                        bestOptionColor: "#00517B",
                                        arrowBackgroundColor: {
                                            lighter: `${THEME.COLORS.PRIMARY_900}A0`,
                                            darker: THEME.COLORS.PRIMARY_900
                                        },
                                        cardStyle: (width, index) => ({
                                            width: width < breakpoint ? width * 0.8 : width * 0.30,
                                            height: "100%",
                                            borderWidth: "4px",
                                            borderStyle: "solid",
                                            borderColor: THEME.COLORS.PRIMARY_700,
                                            backgroundColor: index < 1 ? THEME.COLORS.PRIMARY_700 : THEME.COLORS.PRIMARY_800, // Alternate the background color based on the index
                                        }
                                        ),
                                        titleStyle: (width, index) => ({
                                            fontSize: width < breakpoint ? 34 : 40,
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            color: index < 1 ? THEME.COLORS.PRIMARY_800 : THEME.COLORS.PRIMARY_700, // Alternate the text color based on the index
                                        }),
                                        pricingStyle: (width, index) => ({
                                            fontSize: width < breakpoint ? 38 : 50,
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            color: index < 1 ? THEME.COLORS.PRIMARY_800 : THEME.COLORS.PRIMARY_700, // Alternate the text color based on the index
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                        }),
                                        infoStyle: (width, index) => ({
                                            textAlign: "left",
                                            fontSize: width < breakpoint ? 14 : 16,
                                            color: index < 1 ? THEME.COLORS.PRIMARY_800 : THEME.COLORS.PRIMARY_700, // Alternate the text color based on the index
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        buttonStyle: (width, index) => ({
                                            color: index < 1 ? THEME.COLORS.PRIMARY_800 : THEME.COLORS.PRIMARY_700, // Alternate the text color based on the index
                                            style: {
                                                borderRadius: borderRadiusButtons,
                                                paddingVertical: width < breakpoint ? 14 : 18,
                                            },
                                            titleStyle: {
                                                fontWeight: 'bold',
                                                color: index < 1 ? THEME.COLORS.PRIMARY_700 : THEME.COLORS.PRIMARY_800, // Alternate the text color based on the index
                                                fontFamily: THEME.FONTFAMILY.TITLE,
                                                fontSize: width < breakpoint ? 16 : 22,
                                            },
                                        }),
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            flex: 1,
                            backgroundColor: THEME.COLORS.PRIMARY_800,
                            paddingVertical: "2rem"
                        }),
                    },
                ],
            },
            // add more pages as needed
        ],
    },
    // add more clients as needed
];
